import React from "react"
import { MDBContainer, MDBRow, MDBCol } from "mdbreact"
import QuickContactForm from "../components/QuickContactForm"

import Layout from "../components/TLCLayout"

var contactRowStyle = {
    display: 'flex',
    flexDirection: 'row',
}

var contactBoxStyle = {
    display: 'flex',
    flextDirection: 'row',
    marginTop: '1em'
}

var contactBoxTextStyle = {
    fontSize: '1.5em',
}

var iconStyle = {
    fontSize: '30px',
    width: '30px',
    marginRight: '0.25em',
}

const ContactPage = () => (
    <Layout heroTitle="Contact Us" heroImageUrl="./images/hero-dark.jpg">
        <MDBContainer style={{paddingTop: '50px', paddingBottom: '50px'}}>
            <MDBRow>
                <MDBCol md="7">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3101.607978375888!2d-77.41348358490097!3d38.978618549757954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b63870208995b9%3A0x61c7d6f1f470951f!2s106%20Oakgrove%20Rd%20Suite%20L%2C%20Sterling%2C%20VA%2020166!5e0!3m2!1sen!2sus!4v1581902422110!5m2!1sen!2sus" width="100%" height="250" frameBorder="0" style={{border:0}} allowFullScreen=""></iframe>
                    <p style={{paddingTop: '25px'}}>
                        Our shop is easily accessible from Rt. 28 and right off of Old Ox Road. Reach out to us over email, the phone, or using the easy quote form on this page and we'll be in touch soon!
                    </p>
                    <div style={contactRowStyle}>
                        <div style={contactBoxStyle}>
                            <i className="fas fa-map-marker-alt" style={iconStyle}></i>                        
                            <div style={contactBoxTextStyle}>
                                106 Oakgrove Rd.<br />
                                Suite L<br />
                                Dulles, VA 20166
                            </div>
                        </div>
                        <div style={{paddingLeft: '100px'}}>
                            <div style={contactBoxStyle}>
                                <i className="fas fa-envelope" style={iconStyle}></i>                        
                                <div style={contactBoxTextStyle}>
                                    <a href="mailto:info@tlcautodetail.com">info@tlcautodetail.com</a>
                                </div>
                            </div>
                            <div style={contactBoxStyle}>
                                <i className="fas fa-phone" style={iconStyle}></i>                        
                                <div style={contactBoxTextStyle}>
                                    (703) 962-7106
                                </div>
                            </div>   
                        </div>
                    </div>
                </MDBCol>
                <MDBCol md="5">
                    <QuickContactForm />
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    </Layout>
)

export default ContactPage